body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-container {
  width: 800px;
  margin: 40px auto;
}

.question-box {
  background-color: lightgray;
  padding: 10px;
  width: auto;
}

#header {
  color: white;
  background-color: black;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

#header > input {
  width: 50px;
  border-width: 0px;
  border-radius: 3px;
  text-align: center;
}

#header > button {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: crimson;
  color: white;
}

.red-background {
  background-color: crimson;
}
.red-text {
  color: crimson;
}

.card {
  color: white;
  width: 350px;
  background-color: maroon;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;
  margin: 10px 0;
  border-radius: 5px;
}

.card h3 {
  margin: 0;
}
